.header{
    font-size: 18px;
    font-family: 'Montserrat-SemiBold', sans-serif;
}
.header_logo {
    flex-grow: 1;
    display: flex;
    align-items: center;
}
.header_logo_title{
    font-size: 22px;
    color: #000000;
    margin-left: 10px;
    text-transform: uppercase;
}
.header_logo_title>span{
    color: #FF0000;
}
.header nav{
    color: #707070;
    z-index: 21;
    position: relative;
}
.header nav>div{
    white-space: nowrap;
}
/*------------footer START--------------*/
footer {
    margin-top: 67px;
    border-top: 1px solid #FF0000;
    padding: 22px 0;
    text-align: right;
}

footer p {
    color: #707070;
}

footer p span {
    color: #FF0000;
    white-space: nowrap;
}

/*------------footer END--------------*/
@media (max-width: 959px) {
    .header {
        background-color: #F81B1B;
        height: 207px;
    }

    .header_inner {
        flex-direction: column;
    }

    .header_logo_title {
        display: none;
    }

    .header nav {
        font-size: 14px;
        color: #fff !important;
    }

    .header header {
        padding-top: 23px;
    }
    footer {
        margin-top: 22px;
        padding: 15px 0;
    }
}
@media (max-width: 400px) {
    .header nav {
        font-size: 12px;
    }
    .header nav >div{
        padding-left: 5px ;
        padding-right: 5px;
    }
}
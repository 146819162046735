/*----------------- Global style START -----------*/
body {
    font-family: 'Montserrat-Regular', sans-serif;
}

/* width */
body::-webkit-scrollbar {
    width: 5px;
}

/* Track */
body::-webkit-scrollbar-track {
    background: #eaeaea;
}

/* Handle */
body::-webkit-scrollbar-thumb {
    background: #F81B1B;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

*, ::after, ::before {
    box-sizing: border-box;
}

.font-normal {
    font-family: 'Montserrat-Regular', sans-serif;
}

.font-medium {
    font-family: 'Montserrat-Medium', sans-serif;
}

.font-bold {
    font-family: 'Montserrat-SemiBold', sans-serif;
}

input, textarea {
    display: block;
    width: 100%;
    padding: .938rem .75rem;
    font-size: 14px;
    line-height: 18px;
    color: #707070 !important;
    background-color: #F2F2F2 !important;
    background-clip: padding-box;
    border-radius: 5px;
    border: none;
    outline: none;
    font-family: 'Montserrat-Medium', sans-serif;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input::placeholder, textarea::placeholder {
    color: #707070 !important;
    opacity: .45;
    font-family: 'Montserrat-Medium', sans-serif;
}

input:-ms-input-placeholder, textarea::-ms-input-placeholder {
    color: #707070 !important;
    opacity: .45;
    font-family: 'Montserrat-Medium', sans-serif;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
    color: #707070 !important;
    opacity: .45;
    font-family: 'Montserrat-Medium', sans-serif;
}

input.error {
    border-color: red;
}

label {
    display: block;
    margin-bottom: .5rem;
}

.input-feedback {
    color: red;
    margin-top: .25rem;
    font-size: 14px;
}

h2 {
    font-size: 30px;
    color: #FF0000;
    margin-bottom: 34px;
    margin-top: 67px;
    font-family: 'Montserrat-SemiBold', sans-serif;
}
/*----------------- Global style END -----------*/
.main_page {
    display: flex;
}

.main_page > .left_content {
    background-color: #F81B1B;
    flex: 0 0 436px;
    max-width: 436px;
}

.main_page > .right_content {
    padding-left: 60px;
    padding-right: 150px;
    flex: 0 0 calc(100% - 436px);
    max-width: calc(100% - 436px);
    font-size: 18px;
    background-color: #fff;
}
.main_page > .right_content p {
    line-height: 27px;
    margin: 0;
    white-space: pre-wrap;
}

.main_content {
    z-index: 11;
    position: relative;
    margin-left: 450px;
}

.about_us {
    margin-top: 150px;
}

/*------------services START--------------*/
.services h3 {
    color: #000000;
    font-size: 20px;
    margin: 0;
    padding-left: 14px;
    font-family: 'Montserrat-Medium', sans-serif;
}

.services ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.services ul li p {
    font-size: 16px;
    opacity: .45;
}

.services .services_title {
    display: inline-flex;
    align-items: center;
    margin: 24px 0 20px 0;
}

.services .services_icon {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
}

.services .services_icon img {
    align-self: center;
}

/*------------services END--------------*/
/*------------contact us START--------------*/
.contact_us textarea {
    height: 178px;
    resize: none;
}

.contact_us_wrapper {
    display: flex;
    flex-wrap: wrap;
}

.contact_us_input_wrap:not(:last-of-type) {
    margin-bottom: 17px;
}

.contact_us_wrapper > div {
    position: relative;
    width: 100%;
    min-height: 1px;
}

.contact_us_wrapper {
    margin-top: 30px;
}

.btn_submit {
    min-width: 211px;
    min-height: 48px;
    background-color: #FF0000;
    color: #ffffff;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}

.social_wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.social_wrapper ul > li {
    margin-right: 10px;
    display: flex;
    align-items: center;
    align-self: center;
}

.social-icon {
    min-width: 0 !important;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 50% !important;
}

.contact_us_follow {
    color: #FF0000;
}

.tel {
    color: #707070;
    margin-left: 15px;
}

.social_button {
    margin-top: 20px;
    justify-content: space-between;
}

@media (min-width: 600px) {
    .contact_us_left {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .contact_us_right {
        padding-left: 15px;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .social_button .form_button_wrapper {
        order: 2;
    }

    .social_button {
        display: flex;
        align-items: center;
    }
}

/*------------contact us END--------------*/
/*--------------Responsive------------*/
@media (max-width: 1800px) {
    .main_page > .right_content {
        padding-left: 30px;
        padding-right: 30px;
    }

    .main_content {
        margin-left: 370px;
    }
}

@media (max-width: 1279px) {
    .main_page > .left_content {
        flex: 0 0 300px;
        max-width: 300px;
    }

    .main_page > .right_content {
        flex: 0 0 calc(100% - 300px);
        max-width: calc(100% - 300px);
        padding: 0 20px;
        margin: 0;
    }

    .main_content {
        margin-left: 275px !important;
    }
}

@media (max-width: 1000px) {
    .main_page > .left_content {
        flex: 0 0 250px;
        max-width: 250px;
    }

    .main_page > .right_content {
        flex: 0 0 calc(100% - 250px);
        max-width: calc(100% - 250px);
    }

    .main_content {
        margin-left: 220px !important;
    }
}

@media (max-width: 959px) {
    .main_page > .left_content {
        display: none;
    }

    .main_page > .right_content {
        margin: 0;
        flex: auto;
        max-width: 100%;
        padding: 0;
    }

    .main_content {
        margin: 0 !important;
        padding: 0 50px;
    }

    h2 {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .main_page > .right_content p {
        font-size: 14px;
    }

    .services h3 {
        font-size: 16px;
    }

    .services .services_icon img {
        width: 17px;
    }

    .services .services_icon {
        width: 30px;
        height: 30px;
    }

    .contact_us_wrapper > div {
        margin-top: 15px;
    }

    .btn_submit {
        font-size: 18px;
    }

    .social_wrapper ul {
        justify-content: center;
        margin-top: 20px;
        font-size: 16px;
    }

    .about_us {
        margin-top: 0;
    }
}
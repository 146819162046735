.calc_wrapper {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    /*z-index: 10;*/
}
.calc_wrapper::-webkit-scrollbar {
    display: none;
}

.calc_wrapper_inner{
    position: absolute;
    left: 156px;
    top: 242px;
    width: 100%;
    max-width: 579px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.11);
    background-color: #fff;
    padding: 0 62px;
}
.calc_tabs {
    font-size: 25px;
    color: #BBBBBB !important;
    box-shadow: none!important;
    padding-top: 58px;
}
.calc_tab{
    text-align: left !important;
    min-width: 90px !important;;
    min-height: 40px !important;;
    text-transform: capitalize !important;;
    font-size: 25px !important;;
}
.calc_tab span{
    padding: 0 !important;
}
.calc_field_error{
    color: red;
    position: absolute;
}
.calc_field_input{
    position: relative;
}
.calc_wrapper label{
    font-size: 16px;
    color: #707070;
    opacity: .45;
    margin-bottom: 11px;
    font-family: 'Montserrat-Medium', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.calc_wrapper label span{
color: #FF0000;
}
.calc_submit_plus_wrapper{
    padding: 35px 0;
    display: flex;
    justify-content: space-between;
}
.calc_submit_plus_wrapper .calc_btn_submit{
    min-width: 174px;
    min-height: 43px;
}
.calc_submit_plus_wrapper .calc_btn_plus{
    width: 29px;
    height: 29px;
    border-radius: 50%;
    border: 1px solid #FF0000;
    font-size: 18px;
    background-color: #fff;
    color: #FF0000;
    outline: none;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.calc_select_country{
    padding-top: 21px;
}
.calc_del_request_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px dashed #707070;
    padding: 14px 0;
}
.calc_request{
    color: #FF0000;
    font-size: 16px;
    font-family: 'Montserrat-Medium', sans-serif;
}
.calc_btn_del{
    width: 29px;
    height: 29px;
    border: none;
    border-radius: 50%;
    font-size: 14px;
    background-color: #fff;
    color: #000000;
    outline: none;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.calc_exp_imp{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #FF0000;
    padding-bottom: 17px;
}
.exp-imp-title{
    font-size: 25px;
    margin: 0;
    color: #FF0000;
}
.exp-imp-title span{
    margin:0 10px;
}
.calc_exp_imp_single{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 7px;
    line-height: 1;
}
.calc_more_item:nth-of-type(even) .calc_exp_imp_single{
    padding-left: 61px;
}
.calc_more_item:nth-of-type(even) .calc_exp_imp_single_title{
    padding-left: 61px;
}
.calc_more_item:nth-of-type(odd) .calc_exp_imp_single{
    padding-right: 85px;
    border-right: 1px solid #707070;
}
.calc_exp_imp_single .count{
    color: #FF0000;
    font-size: 18px;
}
.calc_exp_imp_single_title{
    color: #FF0000;
    font-size: 16px;
    margin-bottom: 14px;
    margin-top: 0;
}
.calc_exp_imp_single .title{
    color: #707070;
    opacity: .45;
    font-size: 16px;
}
.calc_exp_imp_main_content{
    padding: 28px 12px;
}
.calc_modal_footer{
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
}
.calc_modal_footer .calc_total_price{
    color: #FF0000;
    font-size: 33px;
    margin: 0 14px;
}
.calc_modal_footer .calc_total_title{
    color: #707070;
    font-size: 16px;
}
.calc_modal_footer_inner{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.calc_dialog_content{
    padding: 35px  52px !important;
}
/*-----alert dialog start-----*/
.alert_dialog_main_content{
text-align: center;
    padding: 80px 0;
}
.alert_dialog_main_content .alert_dialog_line{
    margin: 21px auto;
    height: 1px;
    width: 100%;
    max-width: 255px;
    background-color: #FF0000;
    display: block;
}
.alert_dialog_main_content .alert_dialog_content{
    font-size: 20px;
    color: #707070;
}
/*-----alert dialog end-----*/
@media (max-width:1279px) {
    .calc_wrapper_inner{
        left: 30px !important;
        max-width: 520px !important;
    }
}
@media (max-width:1000px) {
    .calc_wrapper_inner{
        left: 30px !important;
        max-width: 440px !important;
    }
}
@media (max-width:959px) {
    .calc_wrapper{
        position: static !important;
        padding: 0 33px 38px 33px;
        margin-top: -72px;
    }
    .calc_wrapper_inner{
        position: static !important;
        max-width: 100% !important;
    }
    .calc_wrapper label{
        font-size: 14px !important;
    }
    .calc_submit_plus_wrapper{
        align-items: center;
    }
    .calc_submit_plus_wrapper .calc_btn_submit{
        min-width: 150px !important;
    }
}
@media (max-width:599px) {
    .calc_dialog_content{
        padding: 25px 20px !important;
    }
    .calc_more_item{
        border-bottom: 1px dashed #707070;
    }
    .calc_exp_imp_single{
        border: none !important;
    }
    .calc_more_item:nth-of-type(odd) .calc_exp_imp_single{
        padding-right: 0;
    }
    .calc_more_item:nth-of-type(even) .calc_exp_imp_single{
        padding-left: 0;
    }
    .calc_more_item:nth-of-type(even) .calc_exp_imp_single_title{
        padding-left: 0;
    }
    .exp-imp-title{
        font-size: 20px;
    }
    .calc_exp_imp_single .title{
        font-size: 14px;
    }
    .calc_modal_footer .calc_total_title{
        font-size: 14px;
    }
    .calc_modal_footer .calc_total_price{
        font-size: 24px;
        margin: 0 10px;
    }
    .calc_modal_footer_inner .calc_modal_submit{
        min-width: 155px;
    }
    .alert_dialog_main_content{
        padding: 0;
    }
    .calc_wrapper_dialog{
        margin: 0 !important;
    }
    .alert_dialog_main_content .alert_dialog_content{
        font-size: 14px;
    }
}
@media (max-width:600px) {
    .calc_tab{
        font-size: 20px !important;
    }
}
@media (max-width:575px) {
    .calc_wrapper_inner{
        padding: 0 30px;
    }
}
@media (max-width:450px) {
    .calc_submit_plus_wrapper .calc_btn_submit{
        min-width: 110px !important;
    }
    .calc_wrapper_inner{
        padding: 0 15px;
    }

}
@media (max-width:350px) {
    .calc_tab{
        font-size: 16px !important;
    }
}